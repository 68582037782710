/** @format */

import { reactive, toRefs } from '@vue/composition-api'
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)
const state = reactive({
	selectedCustomerId: '',
	isSearchActive: true,
})
export default function useSelectedCustomerState() {
	return { ...toRefs(state) }
}
