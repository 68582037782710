<!-- @format -->

<template>
	<div v-if="!loading">
		<section class="hero is-success margin-bottom-20">
			<div class="hero-body">
				<p class="title">Customer Balance Report</p>
				<p class="subtitle">
					Please Select a company to preview unpaid invoices!
				</p>
			</div>
		</section>
		<ve-table
			:rows="rows"
			:columns="columns"
			:onCellClick="onCellClick"
			:onRowDoubleClick="onRowDoubleClick"
			:sort-options="sortOptions"
		>
		</ve-table>
	</div>
</template>

<script>
import Store from '@/store'
import { useQuery } from '@vue/apollo-composable'
import { onMounted, reactive, ref } from '@vue/composition-api'
import storeSelectCompany from '@/_srcv2/pages/transactions/add-transaction/scripts/useSelectCompanyForm.js'
import GetActiveCustomersForPaymentQuery from '@/_srcv2/pages/transactions/add-transaction/graphql/GetActiveCustomersForPaymentQuery.graphql'
import useSelectedCustomerState from '@/_srcv2/pages/admin/unpaid-invoices/components/useSelectedCustomerState.js'

export default {
	name: 'SearchCompanyToGetBalance',

	setup() {
		const { selectedCustomerId, isSearchActive } = useSelectedCustomerState()
		const { columns } = storeSelectCompany()
		const rows = ref([])
		const sortOptions = {
			enabled: true,
			initialSortBy: { field: 'customer_title', type: 'asc' },
		}
		// * ---------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch: refetchCustomers, loading } = useQuery(
			GetActiveCustomersForPaymentQuery,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		const refetchData = () => {
			refetchCustomers().then((result) => {
				rows.value = result.data.customers
				console.log('rows.value', rows.value)
			})
		}
		onMounted(() => {
			refetchData()
		})
		// * -----------------------------------------------------------------
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				console.log('params.row', params.row)
				selectedCustomerId.value = params.row.customer_id
				isSearchActive.value = false
			}
		}
		const onRowDoubleClick = (params) => {
			console.log('params.row', params.row)
			selectedCustomerId.value = params.row.customer_id
			isSearchActive.value = false
		}
		return {
			columns,
			rows,
			onCellClick,
			onRowDoubleClick,
			sortOptions,
			loading,
		}
	},
}
</script>

<style scoped></style>
