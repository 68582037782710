<!-- @format -->

<template>
	<div>
		<div v-if="!isSearchActive">
			<section class="hero is-info margin-bottom-20">
				<div class="hero-body">
					<p class="title">Customer Balance Report</p>
					<p class="subtitle">
						To make new search with another company click New Search Button!
					</p>
				</div>
			</section>
			<div>
				<b-button class="is-danger" @click="onNewSearch"> New Search </b-button>
			</div>
			<div v-if="loading">Loading...</div>
			<div v-else-if="!loading">
				<div class="margin-bottom-30 margin-top-10">
					<vue-good-table
						id="invoices"
						:columns="columns"
						:rows="rows"
						@on-row-dblclick="onRowDoubleClick"
						@on-cell-click="onCellClick"
						styleClass="vgt-table striped bordered"
						theme="black-rhino"
						max-height="700px"
						:fixed-header="true"
						:pagination-options="{
							enabled: true,
							mode: 'records',
							perPage: 10,
							position: 'bottom',
							perPageDropdown: [5, 10, 15, 20],
							dropdownAllowAll: true,
							setCurrentPage: 1,
							jumpFirstOrLast: true,
							firstLabel: 'First',
							lastLabel: 'Last',
							nextLabel: 'next',
							prevLabel: 'prev',
							rowsPerPageLabel: 'Rows per page',
							ofLabel: 'of',
							pageLabel: 'page', // for 'pages' mode
							allLabel: 'All',
						}"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field === 'preview'">
								<b-button class="is-small is-info">Preview</b-button>
							</span>
							<span v-else-if="props.column.field === 'toPay'">
								<p class="text-bold text-green text-align-right">
									{{
										numeral(props.row.toPay).format('0,0.00') +
										' ' +
										props.row.exc
									}}
								</p>
							</span>
							<span v-else-if="props.column.field === 'totalPayment'">
								<p class="text-bold text-blue text-align-right">
									{{
										numeral(props.row.totalPayment).format('0,0.00') +
										' ' +
										props.row.exc
									}}
								</p>
							</span>
							<span v-else-if="props.column.field === 'balance'">
								<p class="text-bold text-red text-align-right">
									{{
										numeral(props.row.balance).format('0,0.00') +
										' ' +
										props.row.exc
									}}
								</p>
							</span>
							<span v-else-if="props.column.field === 'invoiceNumber'">
								<p
									class="text-bold text-blue text-align-right"
									style="text-decoration: underline"
								>
									{{ props.row.invoiceNumber }}
								</p>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-if="isModalVisible">
						<ModalInvoiceView />
					</div>
					<download-csv :data="rows">
						<div class="margin-bottom-30 margin-top-30">
							<b-button class="is-success"> Download the List </b-button>
						</div>
					</download-csv>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import numeral from 'numeral'
import GetUnpaidInvoicesQuery from '@/_srcv2/pages/admin/unpaid-invoices/GetUnpaidInvoicesQuery.graphql'
import { onMounted, reactive, ref } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
import Store from '@/store'
import useProcessReportStore from '@/_srcv2/pages/_reports/create-report/useProcessReportStore'
import useSelectedCustomerState from '@/_srcv2/pages/admin/unpaid-invoices/components/useSelectedCustomerState.js'
import ModalInvoiceView from '@/_srcv2/pages/admin/unpaid-invoices/components/ModalInvoiceView.vue'

export default {
	name: 'CustomerUnpaidInvoices',
	components: {
		ModalInvoiceView,
	},
	setup() {
		const { selectedCustomerId, isSearchActive } = useSelectedCustomerState()
		const onNewSearch = () => (isSearchActive.value = true)
		const searchedInvoices = ref([])
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { loading, refetch } = useQuery(
			GetUnpaidInvoicesQuery,
			() => ({
				our_company: Store.getters.getUserCurrentCompany,
				customer_id: selectedCustomerId.value,
			}),
			options,
		)
		const rows = ref([])
		onMounted(() => {
			refetch().then((result) => {
				console.log('result', result)
				searchedInvoices.value = result.data.document_transactions
				const getTotal = (item) => {
					return (
						item.document_transaction_goods_transactions_rel_aggregate.aggregate
							.sum.line_price_total_credit_exchange / 100
					).toFixed(2)
				}
				const getVat = (item) => {
					return (
						item.document_transaction_goods_transactions_rel_aggregate.aggregate
							.sum.vat_credit_exchange / 100
					).toFixed(2)
				}
				const getRounding = (item) => {
					return (
						Math.round(parseFloat(getTotal(item)) + parseFloat(getVat(item))) -
						(parseFloat(getTotal(item)) + parseFloat(getVat(item)))
					).toFixed(2)
				}
				const getToPay = (item) => {
					return (
						parseFloat(getTotal(item)) +
						parseFloat(getVat(item)) +
						parseFloat(getRounding(item))
					).toFixed(2)
				}
				const getTotalPayment = (item) => {
					return (
						item.invoice_payments_aggregate.aggregate.sum
							.payment_credit_in_exchange === null
							? 0
							: item.invoice_payments_aggregate.aggregate.sum
									.payment_credit_in_exchange / 100
					).toFixed(2)
				}
				const getBalance = (item) => {
					return (
						parseFloat(getToPay(item)) - parseFloat(getTotalPayment(item))
					).toFixed(2)
				}
				rows.value = result.data.document_transactions.map((item) => {
					return {
						invoiceNumber: item.invoice_number,
						invoiceDate: item.invoice_date,
						invoiceDueDate: item.invoice_due_date,
						customer:
							item.customer.customer_id +
							' - ' +
							item.customer.customer_title +
							' - ' +
							item.customer.customer_nickname,
						invoiceTotal: parseFloat(getTotal(item)),
						invoiceVat: parseFloat(getVat(item)),
						rounding: parseFloat(getRounding(item)),
						toPay: parseFloat(getToPay(item)),
						totalPayment: parseFloat(getTotalPayment(item)),
						balance: parseFloat(getBalance(item)),
						exc: item.invoice_exchange_unit,
					}
				})
			})
		})
		// todo ---------------------------------------------------------
		const columns = [
			{
				label: 'Preview',
				field: 'preview',
				width: '95px',
				sortable: false,
			},
			{
				label: 'Invoice Number',
				field: 'invoiceNumber',
				width: '115px',
			},
			{
				label: 'Customer',
				field: 'customer',
				width: '275px',
			},
			{
				label: 'Invoice Date',
				field: 'invoiceDate',
				width: '120px',
			},
			{
				label: 'Invoice Due Date',
				field: 'invoiceDueDate',
				width: '125px',
			},
			{
				label: 'Invoice Total',
				field: 'toPay',
				width: '130px',
			},
			{
				label: 'Payment',
				field: 'totalPayment',
				width: '130px',
			},
			{
				label: 'Balance',
				field: 'balance',
				width: '130px',
			},
		]
		// todo ---------------------------------------------------------------------------------------------
		const {
			reportDocumentType,
			reportDocumentNumber,
			reportDocumentOurCompany,
			isSelectedDocumentLocked,
			isModalVisible,
			reportCustomer,
			reportDocumentDate,
			reportDocumentStatus,
			isSelectedDocumentReported,
		} = useProcessReportStore()
		// todo -------------------------------------------------------------------------------------------------
		const setVariables = (row) => {
			return new Promise((resolve, reject) => {
				if (row !== null || undefined) {
					console.log('*** setVariables row', row)
					reportDocumentType.value = 'sale-invoice'
					reportDocumentNumber.value = row.invoiceNumber
					reportDocumentOurCompany.value = Store.getters.getUserCurrentCompany
					isSelectedDocumentLocked.value = true
					isSelectedDocumentReported.value = true
					reportCustomer.value = row.customer
					reportDocumentDate.value = row.invoiceDate
					reportDocumentStatus.value = 'locked'
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// todo ---------------------------------------------------------------------------

		const getPdfReport = (row) => {
			setVariables(row).then(() => (isModalVisible.value = true))
		}
		// todo -------------------------------------------------------
		// ('select' || 'invoiceNumber')
		const onCellClick = (params) => {
			if (
				params.column.field === 'invoiceNumber' ||
				params.column.field === 'preview'
			) {
				getPdfReport(params.row)
			}
		}
		const onRowDoubleClick = (params) => {
			getPdfReport(params.row)
		}
		return {
			loading,
			searchedInvoices,
			rows,
			columns,
			numeral,
			isSearchActive,
			onNewSearch,
			onCellClick,
			onRowDoubleClick,
			isModalVisible,
		}
	},
}
</script>

<style scoped></style>
