var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isSearchActive)?_c('div',[_vm._m(0),_c('div',[_c('b-button',{staticClass:"is-danger",on:{"click":_vm.onNewSearch}},[_vm._v(" New Search ")])],1),(_vm.loading)?_c('div',[_vm._v("Loading...")]):(!_vm.loading)?_c('div',[_c('div',{staticClass:"margin-bottom-30 margin-top-10"},[_c('vue-good-table',{attrs:{"id":"invoices","columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table striped bordered","theme":"black-rhino","max-height":"700px","fixed-header":true,"pagination-options":{
						enabled: true,
						mode: 'records',
						perPage: 10,
						position: 'bottom',
						perPageDropdown: [5, 10, 15, 20],
						dropdownAllowAll: true,
						setCurrentPage: 1,
						jumpFirstOrLast: true,
						firstLabel: 'First',
						lastLabel: 'Last',
						nextLabel: 'next',
						prevLabel: 'prev',
						rowsPerPageLabel: 'Rows per page',
						ofLabel: 'of',
						pageLabel: 'page', // for 'pages' mode
						allLabel: 'All',
					}},on:{"on-row-dblclick":_vm.onRowDoubleClick,"on-cell-click":_vm.onCellClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'preview')?_c('span',[_c('b-button',{staticClass:"is-small is-info"},[_vm._v("Preview")])],1):(props.column.field === 'toPay')?_c('span',[_c('p',{staticClass:"text-bold text-green text-align-right"},[_vm._v(" "+_vm._s(_vm.numeral(props.row.toPay).format('0,0.00') + ' ' + props.row.exc)+" ")])]):(props.column.field === 'totalPayment')?_c('span',[_c('p',{staticClass:"text-bold text-blue text-align-right"},[_vm._v(" "+_vm._s(_vm.numeral(props.row.totalPayment).format('0,0.00') + ' ' + props.row.exc)+" ")])]):(props.column.field === 'balance')?_c('span',[_c('p',{staticClass:"text-bold text-red text-align-right"},[_vm._v(" "+_vm._s(_vm.numeral(props.row.balance).format('0,0.00') + ' ' + props.row.exc)+" ")])]):(props.column.field === 'invoiceNumber')?_c('span',[_c('p',{staticClass:"text-bold text-blue text-align-right",staticStyle:{"text-decoration":"underline"}},[_vm._v(" "+_vm._s(props.row.invoiceNumber)+" ")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,2422425476)}),(_vm.isModalVisible)?_c('div',[_c('ModalInvoiceView')],1):_vm._e(),_c('download-csv',{attrs:{"data":_vm.rows}},[_c('div',{staticClass:"margin-bottom-30 margin-top-30"},[_c('b-button',{staticClass:"is-success"},[_vm._v(" Download the List ")])],1)])],1)]):_vm._e()]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"hero is-info margin-bottom-20"},[_c('div',{staticClass:"hero-body"},[_c('p',{staticClass:"title"},[_vm._v("Customer Balance Report")]),_c('p',{staticClass:"subtitle"},[_vm._v(" To make new search with another company click New Search Button! ")])])])}]

export { render, staticRenderFns }