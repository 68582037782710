<!-- @format -->

<template>
	<div>
		<layout-wide>
			<sub-navbar page="Customer" />
			<div v-if="isSearchActive">
				<SearchCompanyToGetBalance />
			</div>
			<div v-if="!isSearchActive">
				<CustomerUnpaidInvoices />
			</div>
		</layout-wide>
	</div>
</template>

<script>
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'
import CustomerUnpaidInvoices from '@/_srcv2/pages/admin/unpaid-invoices/components/CustomerUnpaidInvoices.vue'
import SearchCompanyToGetBalance from '@/_srcv2/pages/admin/unpaid-invoices/components/SearchCompanyToGetBalance.vue'
import useSelectedCustomerState from '@/_srcv2/pages/admin/unpaid-invoices/components/useSelectedCustomerState.js'

export default {
	name: 'GetUnpaidInvoices',
	components: {
		SubNavbar,
		SearchCompanyToGetBalance,
		CustomerUnpaidInvoices,
	},
	setup() {
		const { isSearchActive } = useSelectedCustomerState()
		const onNewSearch = () => (isSearchActive.value = true)

		return {
			isSearchActive,
			onNewSearch,
		}
	},
}
</script>

<style scoped></style>
